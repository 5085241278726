<template>
  <BaseModular subTitle="新闻中心" title="">
    <div class="index_news_center_wrap">
      <div class="index_news_center_left">
        <el-carousel :interval="5000" style="height: 100%; width: 100%">
          <el-carousel-item
            v-for="item in imgData.index_newsCenter"
            :key="item"
            style="height: 380px !important"
          >
            <img style="height: 380px" :src="item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="index_news_center_right">
        <ul>
          <li
            v-for="item in NewsData"
            :key="item"
            style="margin-top: 20px; cursor: pointer"
            @click="setHtml(item)"
          >
            <div class="index_news_center_item_title">
              {{ item.title }}
            </div>
            <div class="index_news_center_item_bottom">
              <p>{{ item.description }}</p>
              <p>发布时间：{{ item.createTime }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </BaseModular>
</template>
  
  <script>
import imgData from "@/data";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import BaseModular from "@/components/BaseModular/BaseModular";
import { storeToRefs } from "pinia";
import { mainStore, stateStore } from "@/store";
export default {
  components: { BaseModular },
  setup() {
    const store = mainStore();
    const NewsData = computed(() => storeToRefs(store).newsAllData);
    const router = useRouter();
    const AdData = computed(() => storeToRefs(mainStore()).AdData);
    const setHtml = (item) => {
      stateStore().setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id, type: "新闻详情" },
        }).href,
        "_blank"
      );
    };
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const data = reactive({
      NewsData,
      imgData,
      AdData,
    });

    return {
      ...data,
      getUrl,
      setHtml,
    };
  },
};
</script>
  
  <style scoped>
@import url("./NewsCenter.css");
</style>
  
  