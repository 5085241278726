<template>
  <div class="modular_all_wrap">
    <div class="modular_wrap">
      <div class="modular_subtitle">{{ subTitle }}</div>

      <div class="modular_line">
        <div class="modular_line_left"></div>
        <div class="modular_line_right"></div>
      </div>

      <!-- <div class="modular_title">
        {{ title }}
      </div> -->

      <div class="modular_li_wrap">
        <div
          @click="changeActiveList(item.code)"
          class="modular_li_item"
          v-for="item in ListData"
          :key="item.code"
          :style="`width:${100 / ListData.length}%;${
            item.code == ActiveList
              ? 'background:#009FFF'
              : 'background: #333333'
          }`"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="modular_content_wrap">
        <div class="modular_content_left">
          <img
            :style="!CurrentPage == 0 ? '' : 'opacity: 0.5'"
            :src="modularImg.Left"
            @click="changeCurrentPage('pre')"
          />
          <!-- style="cursor: pointer" -->
        </div>
        <div class="modular_content" v-if="loadData">
          <div
            style="cursor: pointer"
            v-for="(item, index) in loadData"
            :key="item"
            @click="setHtml(item)"
            :class="
              (index + 1) % 4 == 0
                ? 'modular_content_item2'
                : 'modular_content_item'
            "
          >
            <!-- :style="`width:${(100/(LiImgData.length))-3}%`" -->
            <div class="modular_content_item_top">
              <img :src="item.url" />
              <div class="modular_content_item_top_p_wrap">
                <p>{{ item.title }}</p>
              </div>
            </div>

            <div class="modular_content_item_center">
              <div class="modular_content_item_subTitle">
                {{ item.subTitle }}
              </div>
              <div
                class="modular_content_item_status"
                :style="
                  item.isEnd ? 'background:#009FFF' : 'background: #FA9028;'
                "
              >
                {{ item.isEnd ? "进行中" : "已截止" }}
              </div>
              <!-- <div class="modular_content_item_content">
                {{ item.content | ellipsis}}
              </div> -->
              <div
                class="modular_content_item_content"
                v-html="$options.filters.ellipsis(item.content)"
              ></div>
            </div>

            <div class="modular_content_item_bottom">
              <div class="modular_content_item_bottom_price">
                招标控制价：<span
                  >{{ Number(item.price).toFixed(2)
                  }}{{
                    item.contractRecksonPriceUnit == 0 ? "元" : "万元"
                  }}</span
                >
              </div>
              <div
                class="modular_content_item_bottom_endTime"
                v-if="item.endtime"
              >
                <p>
                  截止时间：<span>{{ item.endtime }}</span>
                </p>
                <img
                  :src="modularImg.rightArrow"
                  :style="item.content ? '' : 'visibility:hidden'"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- -if="!ListData &&" -->
        <div class="modular_content" v-else>
          <div
            style="cursor: pointer"
            v-for="(item, index) in mockData"
            :key="item"
            :class="
              (index + 1) % 4 == 0
                ? 'modular_content_item2'
                : 'modular_content_item'
            "
          >
            <div class="modular_content_item_top">
              <img :src="item.url" />
              <div class="modular_content_item_top_p_wrap">
                <p>{{ item.title }}</p>
              </div>
            </div>

            <div class="modular_content_item_center">
              <div class="modular_content_item_subTitle">
                {{ item.subTitle }}
              </div>
              <div
                class="modular_content_item_status"
                :style="
                  item.isEnd ? 'background:#009FFF' : 'background: #FA9028;'
                "
              >
                {{ item.isEnd ? "进行中" : "已截止" }}
              </div>
              <div
                class="modular_content_item_content"
                v-html="$options.filters.ellipsis(item.content)"
              ></div>
            </div>
          </div>
        </div>

        <div class="modular_content_right">
          <img
            :src="modularImg.Right"
            @click="changeCurrentPage('next')"
            :style="showRightImg ? '' : 'opacity: 0.5'"
          />
        </div>
      </div>
      <div class="modular_btn" style="cursor: pointer" @click="toTable">
        {{ btnTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { stateStore, mainStore } from "@/store";
import { useRouter } from "vue-router";
import _ from "lodash";
import { computed, ref } from "vue";
export default {
  filters: {
    ellipsis(value) {
      value = value.replace(/<\/?.+?>/g, "");
      value = value.replace(/ /g, " ");
      value = value.replace(/.*?\}/g, " ");
      value = value.replace(/.*?\}/g, "");
      return value;
    },
  },
  props: {
    ActiveList: {
      type: String,
      require: true,
    },
    ListData: {
      type: Array,
      require: true,
    },
    LiImgData: {
      type: Array,
      require: true,
    },
    subTitle: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    CurrentPage: {
      type: Number,
    },
    type: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const showRightImg = ref(true);
    const storeOfstate = stateStore();
    const storeOfmain = mainStore();
    const router = useRouter();
    const changeActiveList = (code) => {
      emit("changeActiveList", code);
    };
    const loadData = computed(() => {
      const data = props.LiImgData ? Object.values(props.LiImgData) : [];
      if (!_.chunk(data, 4)[props.CurrentPage + 1]) {
        showRightImg.value = false;
      } else {
        showRightImg.value = true;
      }
      return _.chunk(data, 4)[props.CurrentPage];
    });
    // const LiImgData =
    //   props.LiImgData.length > 4
    //     ? props.LiImgData.slice(0, 4)
    //     : props.LiImgData;
    // const data = reactive({
    //   LiImgData,
    // });
    const btnTitle =
      props.type == "工程类" ? "更多工程招标信息" : "更多货物采购信息";
    const setHtml = (item) => {
      storeOfstate.setactiveHtml(
        item.htmlContent,
        item.subTitle,
        item.publishTime,
        item
      );
      // storeOfstate.setData(
      //   "activeType",
      //   props.type == "工程类" ? "工程招标信息详情" : "货物采购信息详情"
      // );
      window.open(
        router.resolve({
          path: `/BiddingInfo`,
          query: {
            id: item.id,
            type:
              props.type == "工程类" ? "工程招标信息详情" : "货物采购信息详情",
          },
        }).href,
        "_blank"
      );
    };
    const toTable = () => {
      if (props.type == "工程类") {
        storeOfmain.setactivePageUrl("/Bidding");
        router.push({
          path: "/Bidding",
        });
      } else {
        storeOfmain.setactivePageUrl("/Purchasing");
        router.push({
          path: "/Purchasing",
        });
      }
    };
    const changeCurrentPage = (type) => {
      if (
        (props.CurrentPage == 0 && type == "pre") ||
        (!showRightImg.value && type == "next")
      ) {
        return;
      }
      emit("changeCurrentPage", type);
    };
    // url: imgData.EngineeringImg[imgKeyArr[Math.floor(Math.random() * imgKeyArr.length)]],
    // const imgKeyArr = ["Item1", "Item2", "Item3", "Item4"];
    const mockData = [
      {
        url: mainStore().CheckEngineerImg(0),
        title: mainStore().CheckEngineerTitle(0),
        subTitle: "叠石桥智慧云产储一体化项目复垦方案编制项目",
        isEnd: false,
        content:
          "根据招投标相关法律法规的规定南通市海门海泰工程项目管理有限公司受南通市海门鹏天建工有限公司",
      },
      {
        url: mainStore().CheckEngineerImg(1),
        title: mainStore().CheckEngineerTitle(1),
        subTitle: "海门港新区C21014商业项目盘扣式脚手架专业分包工程",
        isEnd: false,
        content:
          "本招标项目海门港新区C21014商业项目（1#酒店、商业B、商业C、商业D及相应地库）盘扣式脚手",
      },
      {
        url: mainStore().CheckEngineerImg(2),
        title: mainStore().CheckEngineerTitle(2),
        subTitle: "海门区全域内河码头近期落地实施方案编制项目 招标公告",
        isEnd: false,
        content:
          "根据政府采购相关法律法规的规定，南通东洲工程项目管理有限公司受 南通市海汇交通建设工程有限公",
      },
      {
        url: mainStore().CheckEngineerImg(3),
        title: mainStore().CheckEngineerTitle(3),
        subTitle: "海门港新区C21014商业项目智慧工地采购项目",
        isEnd: false,
        content:
          "根据招投标相关法律法规的规定南通市海门海泰工程项目管理有限公司受南通市海门鹏天建工有限公司",
      },
    ];
    return {
      ...imgData,
      changeActiveList,
      setHtml,
      changeCurrentPage,
      loadData,
      btnTitle,
      toTable,
      showRightImg,
      mockData,
    };
  },
};
</script>

<style scoped>
@import url("./Modular.css");
</style>

