
<template>
  <BasePage
    :bannerUrl="indexBannerImg"
    subTilte="南通市海衡招标有限公司欢迎您"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
  >
    <DlogAd />
    <Service />
    <NewsCenter style="padding-top: 80px" />
    <Purchasing />
    <AdBanner />
    <Engineering />
    <AdBanner2 />
    <HelpCenter ref="HelpCenter" />
  </BasePage>
</template>


<script>
import BasePage from "@/components/BasePage/BasePage";
import imgData from "@/data.js";
import Service from "./components/Service/Service";
import Purchasing from "./components/Purchasing/Purchasing";
import AdBanner from "./components/AdBanner/AdBanner";
import Engineering from "./components/Engineering/Engineering";
import AdBanner2 from "./components/AdBanner2/AdBanner";
import HelpCenter from "./components/HelpCenter/HelpCenter";
import DlogAd from "./components/DlogAd/DlogAd";
import NewsCenter from "./components/NewsCenter/NewsCenter";
import { reactive, computed } from "vue";
import { mainStore } from "@/store/index";
import { storeToRefs } from "pinia";

export default {
  components: {
    BasePage,
    Service,
    Purchasing,
    Engineering,
    HelpCenter,
    AdBanner,
    DlogAd,
    AdBanner2,
    NewsCenter,
  },
  setup() {
    const store = mainStore();
    const AdData = computed(() => {
      return storeToRefs(store).AdData;
    });
    const data = reactive({
      AdData,
    });

    return {
      ...imgData,
      ...data,
    };
  },
  methods: {
    toHelpCenter() {
      this.$refs.HelpCenter.handleToHelpCenter();
    },
  },
};
</script>

<style scoped>
</style>